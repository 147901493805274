import React, { useContext } from "react"
import Helmet from "react-helmet"
import { useData } from "../lib/hooks"
import BannerSection from "../components/BannerSection"
import { TextSection, BackgroundSection, HalfBackgroundSection, HalfCardSection } from "../components/Section"
import BannerContext from "../lib/context"



import VisionImage from '../assets/images/general/177.jpg'


import GroupImage from '../assets/images/church-interior/14-colour.jpg'
import BibleBG from '../assets/images/bible-plain.jpg'

import LivingImage from '../assets/images/church-interior/1.jpg'
import LovingImage from '../assets/images/core/Front5_Cropped.jpg'
import EquippingImage from '../assets/images/core/Front1.jpg'
import ServingImage from '../assets/images/home/serving-food.jpg'


import BlankProfileImage from '../assets/images/blank-profile.png'
import PastorAfiImage from '../assets/images/team/afi1.jpg'
import JuanErzoImage from '../assets/images/team/Juan Erazo Deacon.jpg'
import MervellaFrancisImage from '../assets/images/team/Marvella Francis Deacon.jpg'
// import PaulineMaslineImage from '../assets/images/team/Pauline Masline.jpg'
// import DollyAjalaImage from '../assets/images/team/Dolly Ajala.jpg'
import CarolineImage from '../assets/images/team/Caroline.jpg'

import JohnOpondoImage from '../assets/images/team/Mr John Opondo.png'
import PaulineMaslineImage from '../assets/images/team/Mrs Pauline Maslin.png'
import OlasumboYatesImage from '../assets/images/team/Mrs Olasumbo Yates.png'
import YaaOwusuaImage from '../assets/images/team/Mrs Yaa Owusua.png'
import { queryTeamMembers } from "../lib/API"
// import CarolineImage from '../assets/images/team/Caroline.png'





const AboutUs = () => {
    const siteTitle = "Blackheath and Charlton Baptist Church - About Us"
    // const pageData = useLocalData( getStaticPageData, 'about-us-banners', 'about-us-banners' )
    const pageData = useContext( BannerContext )['about-us-banners']
    const teamMembers = useData( queryTeamMembers )

    const { header, banners } = pageData || { header: null, banners: null }
    // const headerImages = useData( getAboutUsHeader )
    // const headerTitle = "About Us"
    const headerImages = header ? header.images : []
    const headerTitle = header ? header.title : 'About Us'
    const headerText = header ? header.text : ''

    const headerIcon = <span className="icon major"><i className="fas fa-users"></i></span>
    const headerButtons = [
        { url: "/about-us/#one", text: "Learn about us" }
    ]

    
    return (
        <div id="about-us-page">
            {/* <Header images={headerImages} headerText={headerText} headerTitle={headerTitle} tint={0.6} headerButtons={headerButtons} /> */}
            <Helmet title={siteTitle} />
{/* 
            <section data-aos="fade-up" id="who-we-are" className="main style1">
                <div className="grid-wrapper">
                    <div className="col-6">
                        <header className="major">
                            <h2>Who We Are</h2>
                        </header>
                        <p><strong>Blackheath and Charlton Baptist Church</strong> is a Christ-centred, 
                        Spirit led multicultural community  rooted in God’s love with a desire for encountering His presence and to see His will be done in our lives. 
                        </p>
                        <p> 
                            We are a welcoming church family with a heart of praise who always endeavours to let the gospel and example of Jesus Christ find 
                            expression our daily lives by showing our love for those we encounter and being a light in this world.
                        </p>
                    </div>
                    <div className="col-6">
                        <span className="image fit"><img src={GroupImage} alt="" /></span>
                    </div>
                </div>
            </section> */}

            <BackgroundSection 
                id="who-we-are"
                style="style1"
                title="Who We Are"
                tint={0.6}
                blur={0}
                description={`<em>Blackheath and Charlton Baptist Church</em> is a Christ-centred, Spirit-led multicultural community rooted in God’s love, with a passion for experiencing His presence and fulfilling His Will in our lives.  
                    
                    
                    We are a welcoming church family with a heart of praise, committed to expressing the gospel and example of Jesus Christ through our actions - showing love to everyone we meet and being a light in this world. `}
                background={[ GroupImage ]}
                alt

            />
{/* 
            <div className="grid-wrapper" style={{width: '100%', height: '100%', gridGap: '0 0'}}>
                <HalfCardSection
                    title='Living'
                    image={LivingImage}
                >
                    <p>Text here </p><br/>
                    <h4><b>John 10:10b NIV</b></h4>
                    <p>The thief comes only to steal and kill and destroy; I have come that they may have life, and have it to the full</p>
                </HalfCardSection>

                <HalfCardSection
                    title='Loving'
                    image={LovingImage}
                    left={false}
                >
                    <p>Text here </p><br/>
                    <h4><b>John 13:34</b></h4>
                    <p>The thief comes only to steal and kill and destroy; I have come that they may have life, and have it to the full</p>
                </HalfCardSection>

                <HalfCardSection
                    title='Equipping'
                    image={EquippingImage}
                >
                    <p>Text here </p><br/>
                    <h4><b>John 10:10</b></h4>
                    <p>The thief comes only to steal and kill and destroy; I have come that they may have life, and have it to the full</p>
                </HalfCardSection>

                <HalfCardSection
                    title='Serving'
                    image={ServingImage}
                    left={false}
                >
                    <p>Text here </p><br/>
                    <h4><b>John 10:10</b></h4>
                    <p>The thief comes only to steal and kill and destroy; I have come that they may have life, and have it to the full</p>
                </HalfCardSection>
            </div> */}

            {/* <HalfBackgroundSection
                title="Living"
                style="style1 no-padding"
                background={BibleBG}
            >
                <p>Text here </p><br/>
                <h4><b>John 10:10</b></h4>
                <p>The thief comes only to steal and kill and destroy; I have come that they may have life, and have it to the full</p>
            </HalfBackgroundSection>

            <HalfBackgroundSection
                title="Loving"
                style="style1 large"
                background={[BibleBG]}
                alt
            >

            </HalfBackgroundSection>


            <HalfBackgroundSection
                title="Equippinig"
                style="style1 large"
                background={[BibleBG]}
            >

            </HalfBackgroundSection>


            <HalfBackgroundSection
                title="Serve"
                style="style1 large"
                background={[BibleBG]}
                alt
            >

            </HalfBackgroundSection> */}



            <TextSection 
                id="what-we-believe"
                style="style2 large"
                title="What We Believe"
                description={`We believe in the one true God, Jesus Christ our redeemer and Saviour and the manifesting power and gifts of the Holy Spirit.

We believe that God is a Holy, Just and Righteous God and there is no unrighteousness in Him.  

We believe in Jesus Christ as the only Son of God who by His sacrificial act of love was crucified, died and was raised for the forgiveness of our sins and to reconcile humanity back to God.
It is by this love, action and grace that we are saved and not by human works or effort.

We believe in the infallible, living Word of God - the Holy Bible – through which our faith grows and becomes active guiding us to live righteous and sanctified lives.

We believe in the indwelling, outpouring and renewing work of the Holy Spirit which reveals God’s power through miracles.
The Spirit of truth equips and guides us in fulfilling God’s purpose in our lives and His mission here on earth.`}
                // background={[ BelieveImage2 ]}
                

            />

            <BackgroundSection 
                id="vision"
                style="style1"
                title="Vision"
                background={[ VisionImage ]}
                description={` Our Church family lives as a testimony to Jesus Christ - embracing life to its fullest, Loving others as He loves us, equipping and empowering people through God’s Word to live transformative, faith-filled lives, and serving those in need within our church and in communities locally and globally.`}
                
                tint={0.5}
            />


            <section data-aos="fade-up" id="our-team" className="main style1 special">
                    <div className="col-12">
                        <header className="major">
                            <h2>Our Team</h2>
                        </header>
                    </div>
                    
                    <div className='team-section'>
                        {teamMembers.length > 0 && teamMembers.map( member =>
                            <div className="team-member">
                                <span className="image fit rounded"><img src={member.image} alt="" /></span>
                                <h3>{member.name}</h3>
                                <p><strong>{member.role}</strong></p>
                            </div>
                        )}


                        <div className="team-member">
                            <span className="image fit rounded"><img src={PastorAfiImage} alt="" /></span>
                            <h3>Rev. Afi Kirk</h3>
                            <p><strong>Minister</strong></p>
                        </div>
                        {/* <div className="team-member">
                            <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                            <h3>Tunde Omotosho</h3>
                            <p><strong>Treasurer</strong></p>
                        </div> */}
                        <div className="team-member">
                            <span className="image fit rounded"><img src={CarolineImage} alt="" /></span>
                            <h3>Caroline Ntinyari</h3>
                            <p><strong>Acting Administrator</strong></p>
                        </div>
                        {/* <div className="team-member">
                            <span className="image fit rounded"><img className="top" src={DollyAjalaImage} alt="" /></span>
                            <h3>Dolly Ajala</h3>
                            <p><strong>Youth Leader</strong></p>
                        </div> */}
                        {/* <div className="team-member">
                            <span className="image fit rounded"><img className='juan-erazo-img' src={JuanErzoImage} alt="" /></span>
                            <h3>Juan Erazo</h3>
                            <p><strong>Deacon</strong></p>
                        </div> */}
                        {/* <div className="team-member">
                            <span className="image fit rounded"><img src={PaulineMaslineImage} alt="" /></span>
                            <h3>Pauline Maslin</h3>
                            <p><strong>Deacon</strong></p>
                        </div> */}
                        {/* <div className="team-member">
                            <span className="image fit rounded"><img  src={MervellaFrancisImage} alt="" /></span>
                            <h3>Marvella Francis</h3>
                            <p><strong>Deacon</strong></p>
                        </div>
                         */}
                        

                        <div className="team-member">
                            <span className="image fit rounded"><img src={JohnOpondoImage} alt="" /></span>
                            <h3>John Opondo</h3>
                            <p><strong>Deacon</strong></p>
                        </div>
                        <div className="team-member">
                            <span className="image fit rounded"><img  src={OlasumboYatesImage} alt="" /></span>
                            <h3>Olasumbo Yates</h3>
                            <p><strong>Deacon</strong></p>
                        </div>
                        <div className="team-member">
                            <span className="image fit rounded"><img src={PaulineMaslineImage} alt="" /></span>
                            <h3>Pauline Maslin</h3>
                            <p><strong>Deacon</strong></p>
                        </div>
                        <div className="team-member">
                            <span className="image fit rounded"><img  src={YaaOwusuaImage} alt="" /></span>
                            <h3>Yaa Owusua</h3>
                            <p><strong>Deacon</strong></p>
                        </div>
                        
                        
                    </div>
                    

            </section>


{/* 
            <section data-aos="fade-up" id="our-team" className="main style1 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Our Team</h2>
                        </header>
                    </div>
                    
                    <div className="col-4">
                        <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                        <h3>Rev. Afi Kirk</h3>
                        <p><strong>Minister</strong></p>
                    </div>
                    <div className="col-4">
                        <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                        <h3>Tunde Omotosho</h3>
                        <p><strong>Treasurer</strong></p>
                    </div>
                    <div className="col-4">
                        <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                        <h3>Juan Erazo</h3>
                        <p><strong>Deacon</strong></p>
                    </div>
                    <div className="col-4">
                        <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                        <h3>Pauline Maslin</h3>
                        <p><strong>Deacon</strong></p>
                    </div>
                    <div className="col-4">
                        <span className="image fit rounded"><img src={BlankProfileImage} alt="" /></span>
                        <h3>Marvella Francis</h3>
                        <p><strong>Deacon</strong></p>
                    </div>
                    

                </div>
            </section> */}

            <BannerSection banners={banners} />
        </div>
    )
}

export default AboutUs